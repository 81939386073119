import React from 'react'

import { graphql, Link, useStaticQuery } from 'gatsby'
import { nanoid } from 'nanoid'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

import Container from '../Common/Container'
import CardGame from '../Common/Card/CardGame'
import Headings from '../Common/Headings'
import ColoredHeading from '../Common/ColoredHeading'

import { useContent } from '../../hooks/useContent'
import { getThumbnail } from '../../helpers/thumbnail'

import RightChevron from '../../assets/images/icons/svg/icon-right-chevron-color.svg'

import { Pagination } from 'swiper'

const PopularGames = ({ componentData }) => {
  const data = useStaticQuery(graphql`
    query ($content: String = "game") {
      games: allMarkdownRemark(
        filter: {
          frontmatter: { content: { eq: $content }, showIndex: { eq: true } }
        }
        sort: { order: ASC, fields: frontmatter___index }
      ) {
        edges {
          node {
            frontmatter {
              name
              summary
              thumbnail
              googlePlayUrl
              appStoreUrl
              numberOfDownload
              slogan
              index
            }
          }
        }
      }
      images: allFile(filter: { relativeDirectory: { eq: "images/games" } }) {
        edges {
          node {
            relativePath
            name
            relativeDirectory
            childImageSharp {
              gatsbyImageData(
                width: 120
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
                breakpoints: [640, 768, 1024, 1280]
              )
            }
          }
        }
      }
    }
  `)

  const content = useContent(data, 'games')
    console.log(content)
  return (
    <>
      <Container.FullWidth>
        <div className="w-full bg-gray-light pt-10 pb-10 lg:pt-20">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 lg:gap-10">
            <div className="md:col-span-4 lg:col-span-5 xl:col-span-4">
              <Headings level={2}>
                <ColoredHeading text={componentData.heading} />
              </Headings>
            </div>
            <div className="md:col-span-8 lg:col-span-7 xl:col-span-6">
              <p className="typo-medium-14 lg:typo-medium-24 text-gray">
                {componentData.subHeading}
              </p>
            </div>

            <div className="col-span-2 hidden xl:block">
              <Link
                to="/games"
                className="group basis-1/4 flex justify-end items-center space-x-3 hover:underline transition-all hover:cursor-pointer"
              >
                <p className="text-[16px] font-bold font-sans text-secondary">
                  {componentData.viewAll}
                </p>
                <span className="group-hover:translate-x-3 transition-transform">
                  <RightChevron />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </Container.FullWidth>

      <div className="w-full px-5 sm:px-0 lg:px-5 pb-10 lg:pb-20 bg-gray-light">
        <div className="w-full overflow-hidden">
          <div className="">
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              centeredSlides={false}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                1024: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                  centeredSlides: false,
                  pagination: false,
                },
                1440: {
                  slidesPerView: 3.5,
                  spaceBetween: 0,
                  centeredSlides: false,
                  pagination: false,
                },
                1680: {
                  slidesPerView: 4,
                  spaceBetween: 0,
                  centeredSlides: false,
                  pagination: false,
                },
                1920: {
                  slidesPerView: 4.5,
                  spaceBetween: 0,
                  centeredSlides: false,
                  pagination: false,
                },
                2160: {
                  slidesPerView: 5.5,
                  spaceBetween: 0,
                  centeredSlides: false,
                  pagination: false,
                },
                2640: {
                  slidesPerView: 6,
                  spaceBetween: 0,
                  centeredSlides: false,
                  pagination: false,
                },
              }}
              modules={[Pagination]}
              grabCursor={true}
              className="mySwiper"
            >
              {content?.games
                .map(
                  ({
                    name,
                    summary,
                    googlePlayUrl,
                    appStoreUrl,
                    thumbnail,
                    numberOfDownload,
                    slogan,
                  }) => (
                    <SwiperSlide key={nanoid()}>
                      <div className="px-0 sm:px-5 lg:px-0 lg:pl-3 lg:pr-7">
                        <CardGame
                          name={name}
                          summary={summary}
                          appStoreUrl={appStoreUrl}
                          googlePlayUrl={googlePlayUrl}
                          numberOfDownload={numberOfDownload}
                          slogan={slogan}
                          noBorder
                          thumbnail={getThumbnail(content, thumbnail)}
                          isHome={true}
                        />
                      </div>
                    </SwiperSlide>
                  )
                )}
            </Swiper>
            <Link
              to="/games"
              className="group basis-1/4 flex xl:hidden justify-center items-center space-x-3 hover:underline transition-all hover:cursor-pointer mt-10 lg:mt-16"
            >
              <p className="lg:block text-[16px] font-bold font-sans text-secondary">
                {componentData.viewAll}
              </p>
              <span className="lg:block group-hover:translate-x-3 transition-transform">
                <RightChevron />
              </span>
            </Link>

          </div>
        </div>
      </div>
    </>
  )
}

export default PopularGames
