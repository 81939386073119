import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'

import { nanoid } from 'nanoid'

import { useContent } from '../../hooks/useContent'

import Container from '../Common/Container'
import CardBlog from '../Common/Card/CardBlog'
import Headings from '../Common/Headings'
import ColoredHeading from '../Common/ColoredHeading'

import RightChevron from '../../assets/images/icons/svg/icon-right-chevron-color.svg'
import { getThumbnail } from '../../helpers/thumbnail'

const InsightAndUpdates = ({ componentData }) => {
  const data = useStaticQuery(graphql`
    query ($content: String = "blog") {
      allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              heading
              subHeading
              data {
                title
                category
                tags
                thumbnail
                url
                createdDate
              }
            }
          }
        }
      }
      images: allFile(filter: { relativeDirectory: { eq: "images/blogs" } }) {
        edges {
          node {
            relativePath
            name
            relativeDirectory
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
                breakpoints: [640, 768, 1024, 1280]
              )
            }
          }
        }
      }
    }
  `)
  const content = useContent(data)

  return (
    <Container.FullWidth>
      <div className="w-full bg-gray-light py-10 lg:py-20">
        <div className="w-full lg:flex justify-between items-start">
          <Headings level={2}>
            <ColoredHeading text={componentData.heading} />
          </Headings>

          <Link
            to="/blog"
            className="hidden group lg:flex justify-center items-center space-x-3 hover:underline transition-all hover:cursor-pointer"
          >
            <p className="text-[16px] font-bold font-sans text-secondary">
              View All
            </p>
            <span className="group-hover:translate-x-3 transition-transform">
              <RightChevron />
            </span>
          </Link>
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 lg:gap-10 mt-6 lg:mt-10">
          {content.data
            ?.slice(0, 3)
            .map(({ category, createdDate, title, tags, url, thumbnail }) => (
              <CardBlog
                key={nanoid()}
                category={category}
                createdDate={createdDate}
                title={title}
                tags={tags}
                thumbnail={getThumbnail(content, thumbnail)}
                url={url}
                isOnMainPage
              />
            ))}
        </div>
        <Link
          to="/blog"
          className="group flex mt-[41px] lg:hidden justify-center items-center space-x-3 hover:underline transition-all hover:cursor-pointer"
        >
          <p className="text-[16px] font-bold font-sans text-secondary">
            View All
          </p>
          <span className="group-hover:translate-x-3 transition-transform">
            <RightChevron />
          </span>
        </Link>
      </div>
    </Container.FullWidth>
  )
}

export default InsightAndUpdates
