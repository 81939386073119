import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Headings from '../Common/Headings'
import ColoredHeading from '../Common/ColoredHeading'
import ButtonLink from '../Common/ButtonLink'

const Hero = ({ componentData }) => {
  return (
    <div className="relative mt-8  pt-20 pb-8 lg:pt-0 md:pb-0 w-full overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10 md:h-[50.125vw] md:max-h-[700px] lg:h-[40.5vw]">
        <div className="flex flex-col justify-center items-center md:items-start gap-6">
          <Headings
            level={1}
            className="max-w-[300px] md:max-w-[600px] typo-bold-24 lg:typo-bold-40 text-secondary text-center md:text-left"
          >
            <ColoredHeading text={componentData?.heading} />
          </Headings>

          <p className="typo-medium-16 lg:typo-medium-20 text-gray text-center md:text-left max-w-[300px] md:max-w-[600px]">
            {componentData?.subHeading}
          </p>
          <ButtonLink
            text={String(componentData?.button?.text)}
            to={componentData?.button?.link}
          />
        </div>
        <div className="w-full h-auto md:pt-0 md:w-auto relative md:static">
          <StaticImage
            src="../../assets/images/illustration/hero-img.png"
            alt="APPS"
            layout="constrained"
            placeholder="none"
            height={700}
            quality={100}
            loading="eager"
          />
        </div>
      </div>
    </div>
  )
}

export default Hero
