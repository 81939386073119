import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import FormContact from '../Common/FormContact'
import Container from '../Common/Container'
import Headings from '../Common/Headings'
import ColoredHeading from '../Common/ColoredHeading'
import Seo from '../Seo'

const ContactUs = ({ isOnMainPage = false }) => {
  const data = useStaticQuery(graphql`
    query (
      $content: String = "contact"
      $gdpr_contact_form: String = "gdpr_contact_form"
    ) {
      contact: markdownRemark(frontmatter: { content: { eq: $content } }) {
        frontmatter {
          title
          heading
          subHeading
          subHeadingPage
          viewAll
          contactInfo {
            phone
            email
            address
            googleMapsUrl
          }
          form {
            name {
              label
              placeholder
            }
            surname {
              label
              placeholder
            }
            email {
              label
              placeholder
            }
            phone {
              label
              placeholder
            }
            message {
              label
              placeholder
            }
            button {
              text
            }
          }
        }
      }
      gdprText: markdownRemark(
        frontmatter: { content: { eq: $gdpr_contact_form } }
      ) {
        html
      }
    }
  `)

  const content = data.contact.frontmatter
  const gdprText = data.gdprText

  return (
    <Container>
      <div
        className={classNames(
          'grid grid-cols-1 lg:grid-cols-12 gap-10 my-28 lg:mt-20 mt-10',
          {
            '!mt-20 !mb-0': isOnMainPage,
          }
        )}
      >
        <div className="lg:col-span-5">
          <div className="grid grid-cols-1 gap-8 lg:gap-10">
            <div className="grid grid-cols-1 gap-4 lg:gap-8">
              {!isOnMainPage && (
                <Seo title={content.title} slug={'/contact-us'} />
              )}
              <Headings level={isOnMainPage ? 2 : 1}>
                <ColoredHeading text={content.heading} />
              </Headings>
              <p
                className="typo-medium-14 lg:typo-medium-16 text-gray text-left"
                dangerouslySetInnerHTML={{
                  __html: isOnMainPage
                    ? content.subHeading
                    : content.subHeadingPage,
                }}
              />
            </div>
            <div className="grid grid-cols-1 gap-8 lg:gap-10">
              <div className="flex justify-start items-center gap-5">
                <div className="w-10">
                  <StaticImage
                    src="../../assets/images/icons/png/icon-phone@2x.png"
                    alt="phone icon"
                    layout="fixed"
                    placeholder="blurred"
                    width={40}
                    height={40}
                    loading='eager'
                  />
                </div>
                <a
                  href={`tel:${content.contactInfo.phone}`}
                  className="typo-medium-16 text-secondary text-left"
                >
                  {content.contactInfo.phone}
                </a>
              </div>

              <div className="flex justify-start items-center gap-5">
                <div className="w-10">
                  <StaticImage
                    src="../../assets/images/icons/png/icon-mail@2x.png"
                    alt="mail icon"
                    layout="fixed"
                    placeholder="blurred"
                    width={40}
                    height={40}
                    loading='eager'
                  />
                </div>
                <a
                  className="typo-medium-16 text-secondary text-left"
                  href="mailto:info@apps.com.tr"
                >
                  {content.contactInfo.email}
                </a>
              </div>

              <div className="flex justify-start items-center gap-5">
                <div className="w-10">
                  <StaticImage
                    src="../../assets/images/icons/png/icon-location@2x.png"
                    alt="location icon"
                    layout="fixed"
                    placeholder="blurred"
                    width={40}
                    height={40}
                    quality={100}
                    loading='eager'
                  />
                </div>

                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={content.contactInfo.googleMapsUrl}
                  className="typo-medium-16 text-secondary text-left max-w-[calc(100%-40px)]"
                >
                  {content.contactInfo.address}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:col-span-6 lg:col-start-7">
          <FormContact componentData={content.form} gdprText={gdprText} />
        </div>
      </div>
    </Container>
  )
}

export default ContactUs
