import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

const convertDate = date => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  return `${months[date.getMonth()]} ${date.getDay()}, ${date.getFullYear()}`
}

const CardBlog = ({
  thumbnail = null,
  category,
  createdDate,
  tags,
  title,
  url,
  isOnMainPage,
}) => {
  return (
    <div
      className={classNames(
        'w-full flex flex-col rounded-lg border-[2px] border-gray-dark border-solid bg-white',
        { '!border-white': isOnMainPage }
      )}
    >
      <div className="relative w-full h-[205px] flex rounded-t-lg">
        {thumbnail && (
          <>
            <div className="absolute top-0 left-0 z-10 thumbnail-bg w-full h-[205px] rounded-t-lg"></div>
            <div className="absolute top-0 left-0 z-10 thumbnail-bg w-full h-[205px] rounded-t-lg">
              <p className="typo-bold-14-wide2x md:typo-bold-16-wide2x p-6 text-white">
                {String(category).toLocaleUpperCase()}
              </p>
            </div>
            <div className="w-full h-[205px]">
              {typeof thumbnail === 'string' &&
              String(thumbnail).startsWith('http') ? (
                <img
                  src={thumbnail}
                  alt={title}
                  className="w-full h-[205px] rounded-t-lg"
                />
              ) : (
                <GatsbyImage
                  image={thumbnail}
                  className="w-full h-[205px] rounded-t-lg"
                  alt={title}
                  loading='eager'
                />
              )}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col p-6 lg:p-10">
        <p className="typo-bold-14 md:typo-bold-16 text-gray">
          {convertDate(new Date(`${createdDate}`))}
        </p>
        <a
          href={url}
          className="typo-medium-20 md:typo-medium-24 text-secondary mt-2"
        >
          {title}
        </a>
        <div className="w-full flex flex-row justify-start items-center mt-4 space-x-4">
          {tags?.map(tag => (
            <div
              key={String(tag)}
              className="bg-gray-light py-1.5 px-5 md:py-1 md:px-4 rounded md:rounded-md"
            >
              <p className="typo-medium-14 md:typo-medium-16 text-secondary">
                {String(tag)[0].toLocaleUpperCase() +
                  String(tag).substring(1).toLocaleLowerCase()}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CardBlog
