import * as React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Common/Header'
import Footer from '../components/Common/Footer'
import Hero from '../components/Hero'
import InsightAndUpdates from '../components/InsightAndUpdates'
import PopularGames from '../components/PopularGames'
import WeAreHiring from '../components/WeAreHiring'
import ContactUs from '../components/ContactUs'
import HypercasualGames from '../components/HyperGames'
import Container from '../components/Common/Container'
import Seo from '../components/Seo'
import { useContent } from '../hooks/useContent'

const IndexPage = ({ location, data }) => {
  const content = useContent(data)
  return (
    <>
      <Seo />
      <Container.FullWidth className="!bg-pale-grey">
        <Header location={location} />
        <Hero componentData={content?.hero} />
      </Container.FullWidth>
      <HypercasualGames componentData={content?.hyperCasual} />
      <PopularGames componentData={content?.popularGames} />
      <WeAreHiring componentData={content?.weAreHiring} />
      <InsightAndUpdates componentData={content?.blog} />
      <ContactUs isOnMainPage />
      <Footer />
    </>
  )
}

export const query = graphql`
  query ($content: String = "mainPage", $careerList: String = "career") {
    allMarkdownRemark(filter: { frontmatter: { content: { eq: $content } } }) {
      edges {
        node {
          id
          frontmatter {
            hero {
              heading
              subHeading
              button {
                text
                link
              }
            }
            hyperCasual {
              heading
              subHeading
              items {
                title
                description
                imageName
              }
            }
            popularGames {
              heading
              subHeading
              viewAll
            }
            weAreHiring {
              heading
              subHeading
              viewAll
            }
            blog {
              heading
              viewAll
            }
          }
        }
      }
    }

    careers: allMarkdownRemark(
      filter: { frontmatter: { content: { eq: $careerList } } }
      limit: 2
      sort: { fields: frontmatter___updateDate, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            heading
            summary
            slug
            infos {
              location {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
