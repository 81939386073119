import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

const CardHypercasual = ({ title, description, image }) => {
  return (
    <div className="w-full flex flex-col justify-between items-center pt-10 pb-0">
      <div>
        <GatsbyImage loading='eager' image={image} alt={title} />
      </div>
      <h2 className="typo-bold-20 text-secondary text-center mt-4">{title}</h2>
      <p className="typo-medium-14 text-gray text-center mt-2">{description}</p>
    </div>
  )
}

export default CardHypercasual
