import classNames from 'classnames'
import React from 'react'

const Headings = ({
  level = 1,
  underline = false,
  className = null,
  color = 'text-secondary',
  children,
}) => {
  const CustomTag = `h${level}`

  return (
    <>
      <CustomTag
        className={classNames(
          '',
          {
            'typo-bold-24 lg:typo-bold-40': level === 1 || level === 2,
            'after:content-[""] after:h-0.5 after:w-12 lg:after:w-[70px] after:absolute after:left-0 after:bottom-0 after:inline-block after:bg-secondary relative pb-[14px] lg:pb-10':
              underline,
          },
          color,
          className && className
        )}
      >
        {children}
      </CustomTag>
    </>
  )
}

export default Headings
