import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { nanoid } from 'nanoid'
import cx from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import Container from '../Common/Container'
import Headings from '../Common/Headings'
import ColoredHeading from '../Common/ColoredHeading'
import CardHypercasual from '../Common/Card/CardHypercasual'
import { useContent } from '../../hooks/useContent'
import { getThumbnail } from '../../helpers/thumbnail'
import 'swiper/css'
import 'swiper/css/pagination'

const HypercasualGames = ({ componentData }) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { relativeDirectory: { eq: "images/hypercasual" } }
      ) {
        edges {
          node {
            relativePath
            name
            relativeDirectory
            childImageSharp {
              gatsbyImageData(
                width: 213
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
                breakpoints: [640, 768, 1024, 1280]
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)

  const content = useContent(data)

  return (
    <Container>
      <div className="w-full py-10 lg:py-20">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          <div className="lg:col-span-8 lg:col-start-3 grid gap-4 text-center">
            <div className="max-w-[250px] md:max-w-none mx-auto md:mx-0">
              <Headings level={2}>
                <ColoredHeading text={componentData.heading} />
              </Headings>
            </div>

            <p className="typo-medium-14 lg:typo-medium-20 text-gray text-center">
              {componentData.subHeading}
            </p>
          </div>
        </div>
        <div className="w-full hidden lg:flex lg:flex-row flex-wrap justify-center items-center mt-20 gap-y-10">
          {componentData &&
            componentData?.items?.map((item, index, all) => (
              <React.Fragment key={`hype-${nanoid()}`}>
                <div
                  className={cx(
                    'w-[calc((100%/3)-2px)] flex flex-col justify-start items-center p-10 self-stretch'
                  )}
                >
                  {item.imageName && (
                    <div className="relative w-full pt-[63.75%]">
                      <div className="absolute top-0 left-0 w-full h-full flex justify-center">
                        <GatsbyImage
                          image={getThumbnail(content, item.imageName)}
                          alt={item.title}
                          class="h-full"
                          className="h-full"
                          imgClassName="h-full"
                          loading='eager'
                        />
                      </div>
                    </div>
                  )}
                  <h2 className="typo-bold-24 text-secondary text-center mt-5">
                    {item.title}
                  </h2>
                  <p className="font-semibold text-[17px] text-gray text-center mt-2">
                    {item.description}
                  </p>
                </div>
                {index < all.length - 1 && index % 3 < 2 && (
                  <div
                    key={`hype-line-${nanoid()}`}
                    className="w-px self-stretch flex items-center"
                  >
                    <div className="w-full h-4/5 bg-gray-dark"></div>
                  </div>
                )}
              </React.Fragment>
            ))}
        </div>
        <div className="w-full mb-10 lg:hidden">
          {componentData && (
            <Swiper
              slidesPerView={1}
              spaceBetween={0}
              centeredSlides={false}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              grabCursor={true}
              className="hyperSlider"
            >
              {componentData?.items?.map(
                ({ title, description, imageName }) => (
                  <SwiperSlide key={`hype-slide-${nanoid()}`}>
                    <CardHypercasual
                      title={title}
                      description={description}
                      image={getThumbnail(content, imageName)}
                    />
                  </SwiperSlide>
                )
              )}
            </Swiper>
          )}
        </div>
      </div>
    </Container>
  )
}

export default HypercasualGames
