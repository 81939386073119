import React, { useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import Button from '../Button'
import Modal from '../Modal'
import { sendContactForm } from '../../../services/api'

// Error messages
const REQUIRED = 'Required field'
const INVALID_EMAIL = 'Invalid email address'
const NO_LONGER_THAN = 'It can not be longer than '

const FormContact = ({ componentData, gdprText }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [apiError, setApiError] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async data => {
    if (!executeRecaptcha) return
    try {
      setApiError('')
      setIsSuccess(false)
      setLoading(true)
      const result = await executeRecaptcha('contact_form')
      data.token = result

      const response = await sendContactForm(data)
      if (response.status === 200 && response.data.status) {
        console.log("hello")
        setIsSuccess(true)
        document.getElementById("contactForm").reset()
      }
    } catch (error) {
      setApiError(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    } finally {
      setLoading(false)
    }
  }

  const toggleModalVisibility = () => setIsVisible(prev => !prev)

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id="contactForm">
        <div className="flex flex-col gap-8 lg:grid lg:grid-cols-2 lg:gap-x-10 lg:gap-y-8 pt-2.5">
          <div>
            <label
              className="flex flex-col justify-start items-start"
              htmlFor="input_name"
            >
              <span className="typo-medium-14-wide text-gray">
                {componentData.name.label}
              </span>
              <input
                id="input_name"
                type="text"
                className={classNames('form-element placeholder-secondary', {
                  'border-red focus:border-red': errors.name,
                })}
                placeholder={componentData.name.placeholder}
                {...register('name', {
                  required: REQUIRED,
                  maxLength: { value: 80, message: NO_LONGER_THAN + 80 },
                })}
              />
              {errors?.name && (
                <span className="error-text">{errors.name.message}</span>
              )}
            </label>
          </div>
          <div>
            <label
              className="flex flex-col justify-start items-start"
              htmlFor="input_surname"
            >
              <span className="typo-medium-14-wide text-gray">
                {componentData.surname.label}
              </span>
              <input
                id="input_surname"
                type="text"
                className={classNames('form-element placeholder-secondary', {
                  'border-red focus:border-red': errors.surname,
                })}
                placeholder={componentData.surname.placeholder}
                {...register('surname', {
                  required: REQUIRED,
                  maxLength: { value: 100, message: NO_LONGER_THAN + 100 },
                })}
              />
              {errors?.surname && (
                <span className="error-text">{errors.surname.message}</span>
              )}
            </label>
          </div>
          <div>
            <label
              className="flex flex-col justify-start items-start"
              htmlFor="input_email"
            >
              <span className="typo-medium-14-wide text-gray">
                {componentData.email.label}
              </span>
              <input
                id="input_email"
                type="email"
                className={classNames('form-element placeholder-secondary', {
                  'border-red focus:border-red': errors.email,
                })}
                placeholder={componentData.email.placeholder}
                {...register('email', {
                  required: REQUIRED,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: INVALID_EMAIL,
                  },
                })}
              />
              {errors?.email && (
                <span className="error-text">{errors.email.message}</span>
              )}
            </label>
          </div>
          <div>
            <label
              className="flex flex-col justify-start items-start"
              htmlFor="input_phone"
            >
              <span className="typo-medium-14-wide text-gray">
                {componentData.phone.label}
              </span>
              <input
                id="input_phone"
                type="tel"
                className={classNames('form-element placeholder-secondary', {
                  'border-red focus:border-red': errors.phone,
                })}
                placeholder={componentData.phone.placeholder}
                {...register('phone', {
                  maxLength: 13,
                })}
              />
              {errors?.phone && (
                <span className="error-text">{errors.phone.message}</span>
              )}
            </label>
          </div>
          <div className="col-span-2">
            <label className="flex flex-col justify-start items-start">
              <span className="typo-medium-14-wide text-gray">
                {componentData.message.label}
              </span>
              <textarea
                className={classNames('form-element placeholder-secondary', {
                  'border-red focus:border-red': errors.name,
                })}
                rows="6"
                placeholder={componentData.message.placeholder}
                {...register('message', { required: REQUIRED })}
              ></textarea>
            </label>
            {errors?.message && (
              <span className="error-text">{errors.message.message}</span>
            )}
          </div>
          <div className="col-span-2">
            <div className="flex items-center mb-4">
              <input
                id="input_gdpr"
                type="checkbox"
                value=""
                {...register('gdpr', { required: REQUIRED })}
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="input_gdpr"
                className="ml-2 typo-medium-14 text-gray"
              >
                I have read and agree to
                <span
                  onClick={toggleModalVisibility}
                  onKeyDown={toggleModalVisibility}
                  className="text-secondary font-bold hover:cursor-pointer hover:underline mx-1"
                >
                  Apps's Terms of Service
                </span>
              </label>
            </div>
            {errors?.gdpr && (
              <p className="error-text">{errors.gdpr.message}</p>
            )}
          </div>
          <div className="w-full col-span-2 flex flex-col">
            <Button
              type="submit"
              text={componentData.button.text}
              className="w-full"
              loading={loading}
            />
            <div className="mt-2 typo-medium-14 text-gray">
              This site is protected by reCAPTCHA and the Google
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-1"
              >
                Privacy Policy
              </a>
              and
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-1"
              >
                Terms of Service
              </a>
              apply.
            </div>
            {apiError && (
              <div className="p-4 rounded text-[12px] error-alert mt-4 flex items-center">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="22"
                    height="22"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      fill="currentColor"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"
                    />
                  </svg>
                </span>
                {apiError}
              </div>
            )}
            {isSuccess && (
              <div className="p-4 rounded text-[12px] success-alert mt-4 flex items-center">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="22"
                    height="22"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path fill='currentColor' d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                  </svg>
                </span>
                Your message has been delivered successfully.
              </div>
            )}
          </div>
        </div>
      </form>
      <Modal
        isVisible={isVisible}
        toggleModalVisibility={toggleModalVisibility}
        content={gdprText.html}
      />
    </>
  )
}

export default FormContact
