import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { nanoid } from 'nanoid'
import Container from '../Common/Container'
import Headings from '../Common/Headings'
import ColoredHeading from '../Common/ColoredHeading'
import ButtonLink from '../Common/ButtonLink'
import { useContent } from '../../hooks/useContent'
import RightChevron from '../../assets/images/icons/svg/icon-right-chevron-white.svg'

const WeAreHiring = ({ componentData }) => {
  const data = useStaticQuery(graphql`
    query ($content: String = "career") {
      careers: allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              heading
              summary
              slug
            }
          }
        }
      }
    }
  `)

  const content = useContent(data, 'careers')

  return (
    <Container.FullWidth className="bg-secondary">
      <div className="w-full flex flex-col justify-center items-center bg-secondary py-10 lg:py-20">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-10">
          <div className="lg:col-span-8 lg:col-start-3 grid gap-4 text-center">
            <Headings level={2} color={'text-white'}>
              <ColoredHeading text={componentData.heading} />
            </Headings>

            <p className="typo-medium-14 lg:typo-medium-16 text-white text-center">
              {componentData.subHeading}
            </p>
          </div>
        </div>

        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-10 mt-6 lg:mt-10 mx-auto">
          {content?.careers &&
            content?.careers?.map(({ heading, summary, slug }) => (
              <div
                key={nanoid()}
                className="w-full bg-white rounded-xl p-[15px] lg:px-10 lg:py-6"
              >
                <div className="flex flex-col lg:flex-row justify-center items-start lg:items-center gap-6 lg:gap-0">
                  <div className="flex flex-col justify-center items-start">
                    <h3 className="typo-medium-16 lg:typo-medium-20 text-secondary">
                      {heading}
                    </h3>
                    <p className="typo-medium-14 lg:typo-medium-16 text-gray mt-2">
                      {summary}
                    </p>
                  </div>
                  <div className="w-full lg:w-auto lg:pl-4 self-start lg:self-center">
                    <ButtonLink
                      text="Apply Now"
                      icon={<RightChevron />}
                      to={slug}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Link
          to="/careers"
          className="group flex mt-[41px] lg:mt-[60px] justify-center items-center space-x-3 hover:underline transition-all hover:cursor-pointer"
        >
          <p className="text-[16px] font-bold font-sans text-white">View All</p>
          <span className="group-hover:translate-x-3 transition-transform">
            <RightChevron />
          </span>
        </Link>
      </div>
    </Container.FullWidth>
  )
}

export default WeAreHiring
